import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBESk916g1iVqNLTv0hMRybFGCr4ZL1-6g',
  authDomain: 'codex-jose-salazar.firebaseapp.com',
  projectId: 'codex-jose-salazar',
  storageBucket: 'codex-jose-salazar.appspot.com',
  messagingSenderId: '808339591068',
  appId: '1:808339591068:web:aaebf6aab6d2fa42938259',
  measurementId: 'G-Q50RW4XZ0V',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
